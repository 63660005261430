import ServerErrorJson from "@/lib/prismicio/prismic-server-error-page.json";
import Image from "next/image";
export const ServerError = (props) => {
    const { image, title, content } = ServerErrorJson[process.env.NEXT_PUBLIC_SERVER_ERROR_LOCALE] || Object.values(ServerErrorJson)[0];
    console.log(props);
    return (<div>
            <div className="fixed inset-0 z-10 h-full w-full">
                <Image quality={100} alt={image.alt} src={image.src} fill className="object-cover"/>
            </div>
            <div className="fixed top-1/4 left-0 right-0 z-20 flex flex-col items-center justify-center">
                <h1 className="my-2 max-w-[800px] px-5 text-center text-[24px] font-bold text-black lg:my-5 lg:text-[32px]">
                    {title}
                </h1>
                <p className="my-2 max-w-[800px] px-5 text-center text-xl font-bold text-black lg:my-5 lg:text-[24px]">
                    {content}
                </p>
            </div>
        </div>);
};
