import { LogoLoader } from "@/components";
import { ServerError } from "@/components/ServerError/ServerError";
import "@/styles/index.css";
import { AdpageBody, GtmScript, LastListerProvider, LayoutErrorBoundary, PendingProvider, StatsigProvider, TrustedShopScript, useSessionInit, } from "@jog/react-components";
import { PrismicPreview } from "@prismicio/next";
import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Analytics } from "@vercel/analytics/react";
import { SessionProvider } from "next-auth/react";
import Script from "next/script";
import { useState } from "react";
import { StoresProvider } from "stores/StoresProvider";
function AppContainer({ dehydratedState, children }) {
    const [queryClient] = useState(() => new QueryClient());
    useSessionInit();
    return (<QueryClientProvider client={queryClient}>
            <TrustedShopScript />
            {process.env.NEXT_PUBLIC_SQUEEZELY_ENABLE && <Script id="squeezely-worker" src="/squeezely-worker.js"/>}
            <Hydrate state={dehydratedState}>
                <LastListerProvider>
                    <StoresProvider>
                        <GtmScript />
                        <AdpageBody>
                            <PrismicPreview updatePreviewURL="/api/preview" exitPreviewURL="/api/exit-preview" repositoryName={process.env.NEXT_PUBLIC_PRISMIC_REPO}>
                                <PendingProvider Pending={LogoLoader}>
                                    <StatsigProvider>{children}</StatsigProvider>
                                </PendingProvider>
                            </PrismicPreview>
                        </AdpageBody>
                        <Analytics />
                    </StoresProvider>
                </LastListerProvider>
            </Hydrate>
        </QueryClientProvider>);
}
export default function App({ pageProps, Component }) {
    if (pageProps.dehydratedState) {
        return (<LayoutErrorBoundary Fallback={() => <ServerError from="_app"/>}>
                <SessionProvider>
                    <AppContainer dehydratedState={pageProps.dehydratedState}>
                        <Component {...pageProps}/>
                    </AppContainer>
                </SessionProvider>
            </LayoutErrorBoundary>);
    }
    return <ServerError from="_app"/>;
}
