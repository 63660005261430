import { usePrefetchData } from "@jog/react-components";
import { fetchS3PromoTag, transPromotionTag } from "@jog/shared";
import { useMemo } from "react";
const queryKey = "/prismic/promoTag";
const loadCmsData = async () => fetchS3PromoTag().then((res) => transPromotionTag(res.data));
export function prefetchPromoTag(prefetch) {
    return prefetch([queryKey], loadCmsData);
}
export const usePromotionTag = (promotion) => {
    const { data } = usePrefetchData([queryKey], () => loadCmsData());
    return useMemo(() => { var _a; return data === null || data === void 0 ? void 0 : data[(_a = promotion === null || promotion === void 0 ? void 0 : promotion.trim()) === null || _a === void 0 ? void 0 : _a.toLowerCase()]; }, [data, promotion]);
};
