import { Remove } from "@/components/Icon/Remove";
import { useStores } from "@/stores";
import { Link, Money, NextImage, useI18n, useLineItemLocalized, useLineItemPrice, usePending, } from "@jog/react-components";
import { gtmRemoveFromCart } from "@jog/shared";
import classNames from "classnames";
const Price = ({ finalPrice, firstPrice }) => {
    if (finalPrice) {
        return (<p className="text-right text-sm font-medium text-red-sale">
                <Money centAmount={finalPrice}/>
            </p>);
    }
    return (<p className="text-right text-sm font-medium text-black">
            <Money centAmount={firstPrice}/>
        </p>);
};
export const LineItem = ({ item, className, added, }) => {
    const { image, length, prices, size, quantity } = item;
    const { $t } = useI18n();
    const { setPending } = usePending();
    const { link, name, promotion } = useLineItemLocalized(item);
    const { cartStore } = useStores();
    const { finalPrice, firstPrice } = useLineItemPrice(item);
    const deleteProduct = async () => {
        gtmRemoveFromCart(item, quantity);
        setPending(true);
        await cartStore.deleteCartProduct(item.id, quantity).finally(() => {
            setPending(false);
        });
    };
    return (<li className={classNames("grid grid-cols-[77px,auto] gap-3 border-b border-b-gray-light last-of-type:border-b-0 last-of-type:pb-0", className)}>
            <div>
                <Link href={link}>
                    <NextImage blur={false} height="113" width="77" src={image} alt="Cart item image" className="img-fluid w-full"/>
                </Link>
            </div>
            <div className="flex flex-col justify-between">
                <div>
                    <div className="relative">
                        <Link href={link} className="flex w-[130px]">
                            <h4 className="text-xs font-medium uppercase">{name}</h4>
                        </Link>
                        {!added ? (<button className="absolute right-0 top-0 flex h-6 w-6 items-center justify-center text-gray-icon" onClick={deleteProduct}>
                                <Remove />
                            </button>) : (<></>)}
                    </div>
                    {promotion && (<div className="mt-1 inline-block bg-black px-3 py-1 text-xs text-gray-cream">{promotion}</div>)}
                    <div className="mt-1.5 text-xs">
                        {length
            ? `${$t("Size: {size}/{length}", { size, length })}`
            : `${$t("Size: {size}", { size })}`}
                    </div>
                </div>
                <div>
                    <Price finalPrice={finalPrice} firstPrice={firstPrice}/>
                </div>
            </div>
        </li>);
};
